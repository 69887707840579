<!-- 线索转入 -->
<template>
  <div class="mergeClue">
    <div class="title">{{ $t('选择合并至目标线索') }}</div>
    <van-search
      v-model="searchStr"
      show-action
      :placeholder="$t('请输入完整电话号码/客户姓名搜索目标线索')"
      class="searchBox"
      @search="onSearch">
      <template #action>
        <img class="img" :src="searchIcon" @click="onSearch"/>
      </template>
    </van-search>
    <div class="tips">
      {{ $t('注：功能适用于，人工识别出不同的两条线索为同一个客户。合并后，只需要跟进目标线索，被合并的线索自动战败（微信线索与电话线索合并，默认战败微信线索）') }}
    </div>
    <div v-if="leadList.length > 0" class="leadsInfo">
      <div v-for="(item, index) in leadList" :key="index" class="lead-item common-block">
        <div class="customerInfo">
          <div class="name">{{ item.userName }}</div>
          <div class="phone">{{ item.userMobile }}</div>
          <div class="checkBox">
            <van-checkbox
              :value="item.checked"
              shape="square"
              class="check-item"
              @click.native.stop="onClick(item)">
            </van-checkbox>
          </div>
        </div>
      </div>
    </div>
    <Empty v-else description="" />
    <div class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('取消')}}</van-button>
      <van-button class="btn submit" type="submit" @click="onNext">{{$t('下一步')}}</van-button>
    </div>
  </div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import searchIcon from '@/images/search_icon.svg'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
const reg = new RegExp(/^[0-9]\d{10}$/)
export default {
  name: 'mergeClue',
  components: {
    SearchBarNew,
  },
  data() {
    return {
      searchIcon,
      searchStr: '',
      leadList: [],
      originClueId: '',
      originFollowUserId: '',
      originName:'',
      originMobile: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    const { originClueId, originFollowUserId, originName, originMobile } = this.$route.query
    this.originClueId = originClueId
    this.originFollowUserId = originFollowUserId
    this.originName = originName
    this.originMobile = originMobile

  },
  methods: {
    async onSearch() {
      this.leadList = []
      loading.showLoading()
      const result = await clueServices.dealerClueListPost({ searchStr: this.searchStr, pageSize: 10000, pageNum: 1 })
      const { dataList=[] } = result
      loading.hideLoading()
      this.leadList = dataList.filter(({ id }) => { return id !== this.originClueId }).map(({ ...querys }) => { return {
        checked: false,
        ...querys
      } })
    },
    onClick(data){
      this.leadList.forEach((item) => {
        if (item.id === data.id) {
          item.checked = !item.checked
        } else {
          item.checked = false
        }
      })
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    // 下一步
    onNext() {
      const findIndex = this.leadList.findIndex(({ checked }) => { return checked })
      if (findIndex == -1) {
        this.$toast(this.$t('请选择合并目标线索'))
        return false
      }
      const mergeClue = this.leadList[findIndex] || {}
      // 目标线索和被合并线索不属于统一顾问跟进时 提示并拦截
      if (this.originFollowUserId !== mergeClue.followUserId) {
        this.$toast(this.$t('线索需要同属一个顾问跟进才可合并'))
        return false
      }
      const { originClueId, originName, originMobile } = this
      this.$router.push({
        path: '/clue-merge-confirm',
        query: {
          originClueId,
          originName,
          originMobile,
          mergeClueId: mergeClue.id,
          mergeName: mergeClue.userName,
          mergeMobile: mergeClue.userMobile,
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.mergeClue{
  font-size: 13px;
  color: @black;
  .title{
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    font-size: 14px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  /deep/.van-search{
    height: 54px;
    position: sticky;
    top: 36px;
    z-index: 20;
    .van-search__content{
      border-radius: 20px;
    }
    .van-search__action{
      display: flex;
      align-items: center;
      img{
        width: 22px;
      }
    }
  }
  .tips{
    position: sticky;
    top: 90px;
    padding: 10px;
    background: #fff;
    z-index: 20;
  }
  .leadsInfo{
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 96px;
    overflow-y: auto;
    .lead-item{
      padding: 16px 12px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      .customerInfo{
        font-size: 14px;
        display: flex;
        .phone{
          width: 120px;
        }
        .name{
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .checkBox{
          width: 16px;
          right: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          /deep/.van-checkbox__icon{
            font-size: 16px;
            .van-icon{
              border: 2px solid rgba(13,23,26,0.45);
            }
          }
          /deep/.van-checkbox__icon--checked .van-icon{
            background-color: @black;
            border-color: @black;
            line-height: 1;
          }
        }
      }
    }
  }
}
</style>